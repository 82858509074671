import Button from "./button/Button";
import Logo from "./logo/Logo";
import Intro from "./intro/Intro";
import Start from "./start/Start";
import Base from "./base/Base";
import Listen from "./listen/Listen";
import Speak from "./speak/Speak";
import Read from "./read/Read";
import Write from "./write/Write";
import Navbar from "./navbar/Navbar";
import "./main.css";
import { useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useNavigate, Route, Routes } from "react-router-dom";
function Main() {
    const isPhone = useMediaQuery({ query: '(max-width: 780px)' });
    const [isClicked, setIsClicked] = useState(true);
    const leftSide = isClicked ? isPhone ? " col-span-10" : "" : ""
    const rightSide = isClicked ? isPhone ? " hidden col-auto" : "" : ""
    const [lSide, setLSide] = useState("");
    const [rSide, setRSide] = useState("");
    const [menuBtn, setMenuBtn] = useState(""); 
    const [closeBtn, setCloseBtn] = useState(" hidden"); 
    const laptopl = isPhone ? "" : " col-span-8 ";
    const laptopr = isPhone ? "" : " col-span-2 ";

    const navigate = useNavigate();
    const intropath = "/introduction";
    const startpath = "/start";
    const speakpath = "/speaking";
    const writepath = "/writing";
    const readpath = "/reading";
    const listenpath = "/listening";
    const basepath = "/Basis";
    
    const handleClick = (event, path) => {
        event.preventDefault();
        /*let closest = event.target.closest(".button");
        if (closest != null) {
        clickedId = closest.id;
        localStorage.setItem('clickedId', clickedId);
          let btn = document.getElementById(clickedId);
          let btn2 = document.getElementsByClassName("button-active");
          btn2.item(0).classList.add("button");
          btn2.item(0).classList.remove("button-active");
          btn.classList.add("button-active");
          btn.classList.remove("button");
        }*/
        if(isPhone){
            handleCloseClick();
        }
        navigate(path);
        window.location.reload();
    };

   

    const handleMenuClick = () => {
        setLSide(" hidden col-auto ");
        setRSide(" block col-span-10 ");
        setMenuBtn(" hidden ");
        setCloseBtn(" inline ");
        setIsClicked(false);
    }

    const handleCloseClick = () => {
        setLSide(" block col-span-10 ");
        setRSide(" hidden col-auto ");
        setMenuBtn(" inline ");
        setCloseBtn(" hidden ");
    }
      return (
    <div className="">
        <Navbar handleMenuClick = {handleMenuClick}
                menuBtn = {menuBtn}
                closeBtn = {closeBtn}
                handleCloseClick = {handleCloseClick}
        />
        <div className="main-wrapper grid grid-cols-10 gap-0.5">

            <div id="main-feed"  className={"feed1 p-0  bg-white " + laptopl+ lSide + leftSide}>
                <div>
                    <Routes>
                        <Route  path={intropath} element={<Intro />} />
                        <Route  path={startpath} element={<Start />} />
                        <Route  path={basepath} element={<Base />} />
                        <Route  path={listenpath} element={<Listen />} />
                        <Route  path={speakpath} element={<Speak />} />
                        <Route  path={readpath} element={<Read />} />
                        <Route  path={writepath} element={<Write />} />
                    </Routes>
                </div>
            </div>

            <div  className={"left-side-bar bg-white "+ laptopr + rSide + rightSide }>
                <div className="grid cols-1">
                    <div className="pb-5 pt-4 pl-2">
                        <Logo />
                    </div>
                    <div className="component ">
                        <Button
                            texte={"مقدمة"}
                            icon={"./intro.svg"}
                            handleClick={(event) => {
                            handleClick(event, intropath);
                            }}
                        />
                    </div>
                    <div className="component">
                        <Button
                            texte={"البداية"}
                            icon={"./start.svg"}
                            handleClick={(event) => {
                            handleClick(event, startpath);
                            }}
                        />
                    </div>
                    <div className="component">
                        <Button
                            texte={"الأساس"}
                            icon={"./base.svg"}
                            handleClick={(event) => {
                            handleClick(event, basepath);
                            }}
                        />
                    </div>
                    <div className="component">
                        <Button
                            texte={"الاستماع"}
                            icon={"./listen.svg"}
                            handleClick={(event) => {
                            handleClick(event,  listenpath);
                            }}
                        />
                    </div>
                    <div className="component">
                        <Button
                            texte={"التحدث"}
                            icon={"./speak.svg"}
                            handleClick={(event) => {
                            handleClick(event, speakpath);
                            }}
                        />
                    </div>
                    <div className="component">
                        <Button
                            texte={"القراءة"}
                            icon={"./read.svg"}
                            handleClick={(event) => {
                            handleClick(event, readpath);
                            }}
                        />
                    </div>
                    <div className="component">
                        <Button
                            texte={"الكتابة"}
                            icon={"./write.svg"}
                            handleClick={(event) => {
                            handleClick(event, writepath);
                            }}
                        />
                    </div>
                    <div className="component">
                        <Button
                            texte={"الكتابة"}
                            icon={"./write.svg"}
                            handleClick={(event) => {
                            handleClick(event, writepath);
                            }}
                        />
                    </div>
                    <div className="component">
                        <Button
                            texte={"تسجيل الخروج"}
                            icon={"./logout.svg"}
                            handleClick={(event) => { 
                            handleClick(event, '');
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
        );
}

export default Main;